<template>
  <CRow>
    <CCol sm="12">

      <CCard v-if="mitarbeiter">

        <CCardHeader>
          <CRow>
            <CCol sm="8">
              <CIcon name="cil-storage"/>
              <h5>Übersicht über alle Mitarbeiter</h5>
            </CCol>
            <CCol sm="4" class="text-right">
              <CButton color="info" size="sm" v-on:click="$router.push('/mitarbeiter/rollen')" ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
              </svg> Rollen</CButton>
              &nbsp;
              <CButton color="success" size="sm" v-on:click="newEntry"><CIcon name="cil-library-add"/> Neuer Mitarbeiter</CButton>
            </CCol>
          </CRow>
        </CCardHeader>

        <CCardBody>
          <CRow>
            <CCol sm="12">

              <CTabs>
                <CTab add-tab-classes="mt-1">
                  <template slot="title">
                    <CIcon name="cil-thumb-up"/>
                    <span>Aktiv</span>
                  </template>
                  <Table :mitarbeiter="aktiv" v-model="reload"/>
                </CTab>
                <CTab add-tab-classes="mt-1">
                  <template slot="title">
                    <CIcon name="cil-thumb-down"/>
                    <span>Inaktiv</span>
                  </template>
                  <Table :mitarbeiter="inaktiv" v-model="reload"/>
                </CTab>
              </CTabs>
            </CCol>
          </CRow>
        </CCardBody>

      </CCard>

    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'
import Table from '@/views/Mitarbeiter/Table'

export default {
  name: 'MitarbeiterIndex',
  components: {
    Table
  },
  data () {
    return {
      mitarbeiter: '-',
      reload: ''
    }
  },
  computed: {
    aktiv () {
      if (this.mitarbeiter) {
        return this._.filter(this.mitarbeiter, function (mtrbtr) {
          return mtrbtr.aktiv
        })
      } else {
        return null
      }
    },
    inaktiv () {
      if (this.mitarbeiter) {
        return this._.filter(this.mitarbeiter, function (mtrbtr) {
          return !mtrbtr.aktiv
        })
      } else {
        return null
      }
    }
  },
  mounted () {
    this.loadMitarbeiter()
  },
  watch: {
    reload: function () {
      if (this.reload) {
        this.loadMitarbeiter()
        this.reload = ''
      }
    }
  },
  methods: {
    newEntry () {
      this.$router.push({ path: '/mitarbeiter/neu' })
    },
    loadMitarbeiter () {
      Vue.axios.get('/mitarbeiter/get')
        .then((response) => {
          this.mitarbeiter = response.data
        })
    }
  }
}
</script>
